* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto Serif", serif;
}

body,
html {
  min-height: 100%;
}

.stippet-area {
  display: flex;
  background-color: rgba(230, 232, 232, 0.586);
  flex-direction: column;
  align-items: center;
  border: 1px solid rgba(56, 96, 151, 0.544);
  border-radius: 8px;
  width: 50%;
  height: 100%;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.stippets-list {
  align-items: center;
  height: 100%;
  display: flex;
  align-content: space-evenly;
  flex-direction: column;
  padding: 15px;
  width: 100%;
}

.stippet {
  display: flex;
  flex-direction: column;
  white-space: pre-line;
  line-height: 1.7;
  color: rgb(62, 62, 62);
  border-top: 2px solid rgba(56, 96, 151, 0.855);
  border-bottom: 2px solid rgba(56, 96, 151, 0.862);
  padding: 20px;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 4px;
  font-size: 0.9em;
  width: 100%;
}

.stippet button {
  width: 3.5em;
  height: 1.5em;
  border: none;
  padding: 2px;
  align-self: end;
  background-color: rgb(65, 100, 135);
  color: white;
  border-radius: 4px;
}

.stippet button:hover {
  background-color: rgb(49, 56, 63);
}

.stippet p {
  margin-top: 8px;
}

.logo {
  display: flex;
  align-items: center;
  font-family: monospace;
  padding-left: 20px;
  font-size: 1.5em;
  height: 3em;
  color: white;
  background-color: rgb(56, 95, 151);
}

.test {
  height: 30px;
  width: 100%;
  background-color: rgb(49, 56, 63);
  border-top: 2px solid grey;
  margin-bottom: 50px;
}

@media screen and (max-width: 540px) {
  .stippet-area {
    width: 100%;
    border-radius: 0px;
  }

  .stippets-list {
    padding: 0px 8px 0px 8px;
    width: 100%;
  }

  .test {
    margin-bottom: 0;
  }
}

.stippet-creator {
  background-color: rgba(225, 225, 225, 0.434);
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px 5px 5px 5px;
}

.nw-stippet-title {
  font-size: 1em;
  height: 2em;
  padding: 3px;
  border: 2px solid rgba(56, 96, 151, 0.544);
}

.nw-stippet-body {
  font-size: 1em;
  height: 2em;
  margin-top: 5px;
  padding: 5px;
  height: auto;
  resize: none;
  border: 2px solid rgba(56, 96, 151, 0.544);
}

.submit-btn:hover {
  background-color: rgb(49, 56, 63);
}

.submit-btn {
  height: 2em;
  border: none;
  margin-top: 3px;
  background-color: rgb(56, 95, 151);
  font-weight: bold;
  font-size: 1em;
  border: 2;
  color: white;
  border-radius: 3px;
}

.nw-stippet-button {
  height: 2em;
  width: 98%;
  border: none;
  margin: 6px 8px 4px 8px;
  background-color: rgb(56, 95, 151);
  font-weight: bold;
  font-size: 1em;
  border: 2;
  color: white;
  border-radius: 3px;
}

.nw-stippet-button:hover {
  background-color: rgb(49, 56, 63);
}

.clse-btn {
  height: 2em;
  border-radius: 4px;
  width: 40px;
  font-family: Arial, Helvetica, sans-serif;
  align-self: flex-end;
  border: none;
  margin-bottom: 4px;
  background-color: rgb(56, 95, 151);
  font-weight: bold;
  font-size: 1em;
  border: 2;
  color: white;
}
